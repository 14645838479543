import React, {useContext, useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCardLink,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,MDBTabsPane,
    MDBTabs, MDBIcon, MDBTabsItem, MDBTabsLink,MDBTabsContent
} from "mdb-react-ui-kit";
import MappingPage from "./MappingPage";

function getByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
        if (key === searchValue)
            return key;
    }
}
// Find user full name based on their number
function findFullNameByNumber(users, number){

    if (!Array.isArray(users) || !users.length)
        return number;

    const found = users.find(element =>
        element.phones.find(ph => ph == number)
    );

    if (found){
        const str = found.fullName;
        return str.substring(0, str.indexOf(' '))
    }

    return number;
}

const PhoneRow = ({ number, account, users }) =>{
    const [call, setCall] = useState([]);
    const [text, setText] = useState([]);
    const [name, setName] = useState();
    const [iconsActive, setIconsActive] = useState('voice');

    const handleIconsClick = (value: string) => {
        if (value === iconsActive) {
            return;
        }

        setIconsActive(value);
    };
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);


    useEffect(()=>{
        const transferMap = account.callTransferMap;
        const map = new Map(Object.entries(transferMap))
        const numbers = map.get(number)
        setCall(numbers)
        const textMap = new Map(Object.entries(account.textMap))
        setText(map.get(number))
        const names = new Map(Object.entries(account.phoneNames));
        setName(names.get(number))
        account.users;
    }, [name]);

    const onForwardClick = (event) => {
        event.preventDefault();
        toggleShow();
        console.log("onForwardClicked")
    }
    const ret = basicModal ? (
        <MDBModal show={basicModal} tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>{number}</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>

                        <MDBTabs className='mb-3'>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleIconsClick('voice')} active={iconsActive === 'voice'}>
                                    <MDBIcon fas icon='phone' className='me-2' /> Voice
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleIconsClick('text')} active={iconsActive === 'text'}>
                                    <MDBIcon fas icon='sms' className='me-2' /> SMS
                                </MDBTabsLink>
                            </MDBTabsItem>
                        </MDBTabs>

                        <MDBTabsContent>
                            <MDBTabsPane show={iconsActive === 'voice'}>
                                <MappingPage account={account} number={number} users={users}/>
                            </MDBTabsPane>
                            <MDBTabsPane show={iconsActive === 'text'}>
                                <MappingPage account={account} number={number} users={users}/>
                            </MDBTabsPane>
                        </MDBTabsContent>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={toggleShow}>
                            Close
                        </MDBBtn>
                        <MDBBtn>Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : (


        <tr>
            <td>
                {<MDBCardLink onClick={onForwardClick}>{number}</MDBCardLink>}
                {<p>{name}</p>}
            </td>
            <td>
                {
                    call.map((number, key) => {
                        return (
                            // (!Array.isArray(users) || !users.length) ? ({number}) : (<MDBCardLink  key={key*-1}>{findFullNameByNumber(users, number)}</MDBCardLink>)
                            (!Array.isArray(users) || !users.length) ? <p key={key*-1}>{number}</p> : <p key={key*-1}>{findFullNameByNumber(users, number)}</p>
                        );
                    })
                }
            </td>
            <td>
                {
                    text.map((number,key) => {
                        return (
                            // users === undefined ? (<MDBCardLink key={key*-1}>{number}</MDBCardLink>)
                            //     : (<MDBCardLink onClick={onForwardClick} key={key*-1}>{findFullNameByNumber(users, number)}</MDBCardLink>)
                            (!Array.isArray(users) || !users.length) ? <p key={key*-1}>{number}</p> : <p key={key*-1}>{findFullNameByNumber(users, number)}</p>
                        );
                    })
                }
            </td>
        </tr>
    )
    return ret;
}

export default PhoneRow;