import {MDBDropdownItem, MDBDropdownLink} from "mdb-react-ui-kit";
import React from "react";

const CompanyDropdownItem = ({ active, businessName, id }) => {
    if (!businessName) return <div />;
    return (
        <MDBDropdownItem>
            <MDBDropdownLink href={`/companies/${id}?page=1`}>{businessName}</MDBDropdownLink>
        </MDBDropdownItem>
    );
};

export default CompanyDropdownItem;