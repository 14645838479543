import React, {useContext, useEffect, useState, useMemo} from 'react';
import {useLocation} from "react-router-dom";

import {
    MDBTable, MDBTableHead, MDBTableBody, MDBCardText, MDBBtn, MDBPagination,
    MDBPaginationItem, MDBPaginationLink, MDBModal
} from 'mdb-react-ui-kit';

import Toolbar from "../../Toolbar";
import {
    useFindAllUsersQuery,
    useFindCompanyByIdQuery, useUpdateSettingsMutation,
} from "../../services/clientEngine/btelbAPI";
import {getAuth} from "firebase/auth";
import {app, UserContext} from "../../providers/UserProvider";
import CompanyPageImpl from "./CompanyPageImpl";
const auth = getAuth(app);

const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
const API_KEY = process.env.REACT_APP_apiKey;

function sliceIntoChunks(arr, chunkSize) {
    if (!Array.isArray(arr) || !arr.length)
        return [];

    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

function storeLastAccessedBussiness(companyId){
    const {
        data, error, isLoading
    } = useFindCurrentUserQuery();

    console.log(data);
}

// A custom hook that builds on useLocation to parse
// the query string for you.
//https://v5.reactrouter.com/web/example/query-parameters
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * When page is called with paginated items, i.e.{`/companies/${id}`}?page
 * @param match
 * @returns {JSX.Element}
 * @constructor
 */
export const CompanyPage = ({ match, page }) => {
    // corresponds to the name of the business (db id)
    const companyId  = match.params.id

    let query = useQuery();

    const value = useContext(UserContext);
    const [token, setToken] = useState();
    const [skip, setSkip] = useState(true);

    // customer facing numbers sliced into 5 number chunks for pagination so they will fit one page
    const [slices, setSlices] = useState([]);

    const NUMBER_PER_PAGE = 5;
    const TOTAL_PAGES_ON_SCREEN = 3;
    /**
     * Active page display 5 phones number. The total number of pages is the total number of slices
     */
    const [activePage, setActivePage] = useState(query.get("page"));
    const [offset, setOffset] = useState(0);

    const {
        data: users, usersError, isLoadingUsers
    } = useFindAllUsersQuery({ apiKey: API_KEY, token: token, companyId: companyId}, {skip});

    const {
        data: data, error, isLoading
    } = useFindCompanyByIdQuery({ apiKey: API_KEY, token: token, companyId: companyId}, {skip});

    const [
        updateSettings// This is the destructured mutation result
    ] = useUpdateSettingsMutation()

    // check whether previous or next page control must be enabled or disabled
    const isPreviousEnabled = (activePage > 1) ? "enabled" : "disabled";
    const previousLink = (isPreviousEnabled === "enabled") ?
        (
            <MDBPaginationItem>
                <MDBPaginationLink href={`/companies/${companyId}?page=${activePage-1}`} tabIndex={-1} aria-disabled="false">
                    Previous
                </MDBPaginationLink>
            </MDBPaginationItem>
        )
        :
        (
            <MDBPaginationItem disabled>
                <MDBPaginationLink href={`/companies/${companyId}?page=${activePage-1}`} tabIndex={-1} aria-disabled="true">
                    Previous
                </MDBPaginationLink>
            </MDBPaginationItem>
        );
    const isNextEnabled = (activePage < slices.length) ? "enabled" : "disabled";
    const nextLink = (isNextEnabled === "enabled") ?
        (
            <MDBPaginationItem>
                <MDBPaginationLink href={`/companies/${companyId}?page=${Number(activePage)+Number(1)}`} aria-disabled="false">Next</MDBPaginationLink>
            </MDBPaginationItem>
        )
        :
        (
            <MDBPaginationItem disabled>
                <MDBPaginationLink href={`/companies/${companyId}?page=${Number(activePage)+Number(1)}`} aria-disabled="true">Next</MDBPaginationLink>
            </MDBPaginationItem>
        );


    const storePreference = async () => {
        const settings = {"lastAccessedCompanyId": companyId};
        await updateSettings({apiKey: API_KEY, token: token, data: settings}).unwrap().
            // then(
            //     fulfilled => console.log(fulfilled)).
            catch(rejected => console.error(rejected))

        // await updateSettings({apiKey: API_KEY, token: token, data: settings}).unwrap().
        // then(
        //     fulfilled => console.log(fulfilled)).
        //           catch(rejected => console.error(rejected))
        console.log('settings updated')
    }

    // // First time it draws, it executes
    useEffect(()=>{
        // The values passsed inside the context
        // might be null
        if (value.user == null)
            return;

        if (skip){
            value.user.getIdToken(true).then((token) =>{
                // logic to process token if needed
                setToken(token);
                setSkip(false);


            });
        }

        if (data){
            var chunks = sliceIntoChunks(data.customerFacingNumbers, NUMBER_PER_PAGE);
            //Object.assign({}, [chunks])
            setSlices(chunks);
            storePreference();
        }


    }, [value, isLoadingUsers, data]);

    if (!data || slices[0] === undefined) {
        return (
            <section>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </section>
        )
    }

    return (

            <>
                <Toolbar/>
                <MDBCardText>{data.businessName}</MDBCardText>
                    <CompanyPageImpl
                        numbers={slices[activePage-1]}
                        account={data}
                        users={users} />
                {/*<nav aria-label='Search mappings of the phone numbers for the company'>*/}
                <MDBPagination className='mb-0'>
                    {previousLink}
                    {

                        slices.map( (slice, key) => {
                            return (
                                activePage == (key + 1) ?
                                    (
                                        <MDBPaginationItem key={key} active aria-current='page'>
                                            <MDBPaginationLink key={key} href={`/companies/${companyId}?page=${key+1}`}>
                                                {key + 1}
                                                <span className='visually-hidden'>(current)</span>
                                            </MDBPaginationLink>
                                        </MDBPaginationItem>
                                    ) :
                                    (
                                        <MDBPaginationItem key={key}>
                                            <MDBPaginationLink key={key} href={`/companies/${companyId}?page=${key+1}`}>
                                                {key + 1}
                                            </MDBPaginationLink>
                                        </MDBPaginationItem>
                                    )

                            );
                        })
                    }

                    {nextLink}


                </MDBPagination>
                {/*</nav>*/}
            </>
    )
}
