
// firebase ui
import React, {useEffect} from "react";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from "firebaseui";
import { MDBSpinner } from 'mdb-react-ui-kit';

import UserProvider from "../providers/UserProvider";
import {app} from "../providers/UserProvider";
import {getAuth} from "firebase/auth";
const auth = getAuth(app);

var uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            var user = authResult.user;
            //
            // // if it is a new user, we send notification email
            if (authResult.additionalUserInfo.isNewUser)
            {
                console.log("new signin");
                user.sendEmailVerification({
                    url: "https://localhost:3000",
                });
            }

            return true;
        },
        uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            let spinner = document.getElementById('loader');
            let container = document.getElementById('firebase-container');
            spinner.remove();
        }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    tosUrl: '/terms-and-conditions.html',
    // Privacy policy url.
    privacyPolicyUrl: '/privacy-policy.html'
};

const FirebaseUI = () => {

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // The start method will wait until the DOM is loaded.
        let ui = firebaseui.auth.AuthUI.getInstance() ||
                new firebaseui.auth.AuthUI(firebase.auth());


        ui.start('#firebaseui-auth-container', uiConfig);

        return () => {
            // Your code here
            ui.delete();
        }
    },[]);

    return (
        <div id="firebase-container">
            <div id="firebaseui-auth-container"/>
            <MDBSpinner id="loader" role='status'>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </div>
    );
};

export default FirebaseUI;
