import React, {useContext, useEffect, useState} from "react";
import {
    MDBRow, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBIcon, MDBSideNavCollapse, MDBBtn, MDBSpinner
} from "mdb-react-ui-kit";

import '../HomePage.css';

import {Redirect, useHistory} from "react-router-dom";
import Toolbar from "../Toolbar";
import Footer from "../Footer";

import { useAuthState } from 'react-firebase-hooks/auth';
import {getAuth} from "firebase/auth";
import solutions_img from '../assets/solutions.svg'

// The app gets initialized in the user provider
import {app, UserContext} from "../providers/UserProvider";
import SignInPage from "../auth/SignInPage";
import NewBusiness from "./NewBusiness";
import {
    useFindAllCompaniesQuery,
    useGetSettingsQuery,
    useCreateUserMutation
} from "../services/clientEngine/btelbAPI";

const auth = getAuth(app);

const API_KEY=process.env.REACT_APP_apiKey
/**
 * Main panel that has toolbar and footer
 * @param title
 * @returns {*}
 * @constructor
 */
const Dashboard  = ({ title }) => {
    const [user, loading, error] = useAuthState(auth);
    const value = useContext(UserContext);
    let history = useHistory();
    const [token, setToken] = useState(null);
    const [skip, setSkip] = useState(true);

    const {
        data: settings, settingError, isLoading
    } = useGetSettingsQuery({ apiKey: API_KEY, token: token}, {skip})

    const {
        data: companies, error: findCompaniesError, isLoading: findCompaniesIsLoading
    } = useFindAllCompaniesQuery({ apiKey: API_KEY, token: token}, {skip});

    const [
        createUser// This is the destructured mutation result
    ] = useCreateUserMutation()

    // First time it draws, it executes
    useEffect(()=>{
        if (loading)
            return;

        if (skip){
            if (user){
                user.getIdToken(true).then((token) =>{
                    // logic to process token if needed
                    setToken(token);

                    const userDTO = {"fullName": user.displayName, "email": user.email, "firebaseUid": user.uid};
                    createUser({apiKey: API_KEY, token: token, data: userDTO}).unwrap().
                        then(
                             fulfilled => setSkip(false)).
                        catch(rejected => console.error(rejected))

                    console.log('user updated')
                    })
            }
        }

    },[user, loading, settings, token, companies]);

    // const updateUser = async (user) => {
    //
    // }

    const getPhones = (event) => {
        event.preventDefault();
        history.push('/buyphones')
    }

    let defaultCompany;

    if (typeof companies != "undefined" && Array.isArray(companies) && companies.length > 0){
        // we were able to get the list of companies
        defaultCompany = companies[0].name
    }

    let ret;

    if (value.user === null) {
        // if user is not signed in, we display page that allows user to sign in
        ret = (
            <>
                <Toolbar/>
                <SignInPage/>
                <Footer/>
            </>
        );
    }
    else if (settings && settings.lastAccessedCompanyId){
        // if there are preferences set, we redirect page to show information about specific company
        ret = (
            <>
                <Redirect to={`/companies/${settings.lastAccessedCompanyId}?page=1`}/>
            </>
        );
    } else if (defaultCompany) {
        ret = (
            // at this point preference has not been ever set, we just make an opinionated choice for the user by
            // showing first business found if any
            <>
                <Redirect to={`/companies/${defaultCompany}?page=1`}/>
            </>
        );
    } else {
        ret = (
            // this is a default page when user has not created any businesses yet or businesses could not be retrieved
            // may be add a refresh button in case call to get businesses failed
            <>
                <Toolbar/>
                <NewBusiness/>
                <Footer/>
            </>
        );
    }
    return ret;

    // if (loading){
    //     ret = (<MDBSpinner id="loader" role='status'>
    //         <span className='visually-hidden'>Loading...</span>
    //     </MDBSpinner>);
    // } else if ( (user == 'undefined' || user == null)){
    //     ret =  (<SignInPage/> );
    // } else {
    //     console.log(activeAccountId);
    //     let path = '/accounts/' + {activeAccountId};
    //     let accountPage = (activeAccountId === '' || typeof activeAccountId === 'undefined')? "" :
    //         "";
    //     ret = ( <>
    //         <Toolbar/>
    //         <NewBusiness/>
    //         {/*<MDBBtn>Save</MDBBtn>*/}
    //         {/*<MDBBtn>Canel</MDBBtn>*/}
    //         <Footer/>
    //     </>);
    // }
    return ret;

}

export default Dashboard;