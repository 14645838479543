import React, {useContext, useEffect, useState} from "react";
import {
    MDBCheckbox
} from "mdb-react-ui-kit";

function getByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
        if (key === searchValue)
            return key;
    }
}


/**
 * Display whether user should be enabled or disabled for particular mapping
 * @param name the name of the user
 * @param number the number of the user
 * @param isVoice if voice true, otherwise text
 * @returns {JSX.Element}
 * @constructor
 */
const MappingRow = ({name, number, isVoice}) =>{

    useEffect(()=>{

    }, [name]);

    const onForwardClick = (event) => {
        event.preventDefault();

        console.log("onForwardClicked")
    }

    return (
        <tr>
            <td>
                <MDBCheckbox></MDBCheckbox>
            </td>
            <td>
                {
                    <div>
                        <p>{name}</p>
                        <p>{number}</p>
                    </div>
                }
            </td>
        </tr>
    )
}

export default MappingRow;