import React, {useEffect} from "react";
import {
    MDBTable, MDBTableHead, MDBTableBody
} from "mdb-react-ui-kit";
import PhoneRow from "./PhoneRow";

/**
 * Display all phone numbers and mapping. Allows user navigate to page where voice or text mapping can be configured.
 *
 * @param numbers all numbers that belong to specific company
 * @param account a business id. it is used in specific navigation
 * @param users the list of workers to which voice could be forwarded or sms send
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyPageImpl = ({ numbers, account, users }) =>{

    useEffect(()=>{
    }, []);

    return (
        <>

            <MDBTable hover striped>

                <MDBTableHead >
                    <tr>
                        <th scope='col'>Line</th>
                        <th scope='col'>To</th>
                        <th scope='col'>SMS</th>
                    </tr>

                </MDBTableHead>
                <MDBTableBody>
                    {
                        (!numbers && !Array.isArray(numbers)) ? "" :
                            numbers.map((number, key) => {
                            return  (
                                <PhoneRow
                                    key={key}
                                    number={number}
                                    account={account}
                                    users={users}
                                />
                            );
                        })
                    }
                </MDBTableBody>
            </MDBTable>
        </>
    )
}

export default CompanyPageImpl;