import React, {useEffect, useState} from "react";
import {
    MDBTable, MDBTableHead, MDBTableBody,MDBCheckbox
} from "mdb-react-ui-kit";
import MappingRow from "./MappingRow";

/**
 * Allow user to configure voice and text mappings for the phone number. The page has two tabs: Voice and Text. Each
 * for configuring mapping
 * @param number
 * @param users
 * @returns {JSX.Element}
 * @constructor
 */

const MappingPage = ({ account, number,  users }) =>{
    const [isVoice, setVoice] = useState(true);
    useEffect(()=>{
    }, []);

    return (
        <>
            <MDBTable hover striped>
                <MDBTableHead >
                    <tr>
                        <th scope='col'>
                            <MDBCheckbox></MDBCheckbox>
                        </th>
                        <th scope='col'>To</th>
                    </tr>

                </MDBTableHead>
                <MDBTableBody>
                    {
                        users.map((user, key) => {
                            return  (
                                <MappingRow
                                    key={key}
                                    name={user.fullName}
                                    number={number}
                                    isVoice={isVoice}
                                />
                            );
                        })

                    }
                </MDBTableBody>
            </MDBTable>
        </>
    )
}

export default MappingPage;