
import React, {useEffect, useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import {
    MDBEdgeHeader,
    MDBAnimation,
    MDBJumbotron,
    MDBFreeBird,
    MDBCol,
    MDBContainer, MDBRow
} from 'mdb-react-ui-kit';

import FirebaseUI from "./FirebaseUI";
import RegisterPnl from "./RegisterPnl";
import RegistrationForm from "./RegistrationForm";
import '../HomePage.css';
import {getAuth, onAuthStateChanged} from "firebase/auth";
import firebase from "firebase/compat/app";

import { useAuthState } from 'react-firebase-hooks/auth';
/**
 * We use Firebase auth hooks. See https://github.com/CSFrequency/react-firebase-hooks/tree/master/auth
 *
 * For example of synchronizing 2 sibling components
 *
 * https://stackoverflow.com/questions/61836485/passing-data-to-sibling-components-with-react-hooks
 * https://itnext.io/passing-data-between-sibling-components-in-react-using-context-api-and-react-hooks-fce60f12629a
 *
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */

/**
 * Display options to signin with using supported providers.
 * At the moment, provider can be Google or Email and Password
 */
const app = firebase.app();
const auth = getAuth(app);


const SignInPage = () => {
    const [user, loading, error] = useAuthState(auth);

    let history = useHistory();

    // First time it draws, it executes
    useEffect(()=>{
        if (loading)
            return;
        if (user){
            history.push("/")
        }
        // else {
        //     history.push("/")
        // }
    },[user, loading]);
    return (
        <>
            <MDBAnimation type='zoomIn' duration='500ms'>
                    <MDBRow>
                        <MDBCol md='8' className='mx-auto'>
                            <div className='p-5 text-center bg-light'>
                                <FirebaseUI/>
                                {/* Commented out below because Firebase UI handles registration as well */}
                                {/*<RegisterPnl/>*/}
                            </div>
                        </MDBCol>
                    </MDBRow>
            </MDBAnimation>
        </>
    )
}

export default SignInPage;