import React, {createContext} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

import { MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import Footer from "./Footer";
import firebase from 'firebase/app'
import Toolbar from "./Toolbar";
import SignInPage from "./auth/SignInPage";
import Dashboard from "./pages/Dashboard";
const UserContext = createContext();

function App() {
  return (<div><Routes/></div>);
}

export default App;
