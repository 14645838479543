import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon
} from "mdb-react-ui-kit";
import {useEffect, useContext, useState} from "react";
import CompanyDropdownItem from "./CompanyDropdownItem";
import {getAuth} from "firebase/auth";
import {app, UserContext} from "../providers/UserProvider";

import React from 'react';
import {
    useFindAllCompaniesQuery,
    useGetSettingsQuery,
    useUpdateSettingsMutation
} from "../services/clientEngine/btelbAPI";
import {Redirect} from "react-router-dom";

const auth = getAuth(app);

// const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
// const GATEWAY_URL = 'https://btb-gw-dev-4vrtw1h8.uc.gateway.dev';

const API_KEY = process.env.REACT_APP_apiKey;


export default function CompanySelector(){
    const value = useContext(UserContext);
    const [token, setToken] = useState();
    const [skip, setSkip] = useState(true);

    const {
        data, error, isLoading
    } = useFindAllCompaniesQuery({ apiKey: API_KEY, token: token}, {skip});


        // // First time it draws, it executes
    useEffect(()=>{
        // The values passsed inside the context
        // might be null
        if (value.user == null)
            return;

        if (skip){
            value.user.getIdToken(true).then((token) =>{
                // logic to process token if needed
                setToken(token);
                setSkip(false);
            });
        }

    }, [value, isLoading]);    // // First time it draws, it executes

    async function signOut(){
        try {
            if (auth) {
                await auth.signOut();
                console.log("signed out successfully");
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    let localUser = (value.user) ? value.user.displayName : "";

    let postContent;

    if (value.user === null  && isLoading) {
        postContent = (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    } else if (Array.isArray(data)) {

        postContent = (
            <MDBDropdown>
                <MDBDropdownMenu id="company-selector">
                    {
                        data.map((data, key) => {
                            return (
                                <CompanyDropdownItem active={typeof settings !== "undefined" && settings.lastAccessedCompanyId === data.name} key={key}
                                    businessName={data.businessName}
                                    id={data.name}
                                />
                            );
                        })
                    }
                </MDBDropdownMenu>
                <MDBDropdownToggle tag='a' className='nav-link'>
                    {/*+ Add Business*/}
                    Select Company
                </MDBDropdownToggle>
            </MDBDropdown>
        );
    }
    return <div>{postContent}</div>

}
