import {
    MDBCard,
    MDBCardBody, MDBCardText,
    MDBCardTitle,
    MDBDropdown, MDBDropdownItem, MDBDropdownLink,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBIcon
} from "mdb-react-ui-kit";
import React, {useContext, useEffect} from "react";
import {app} from "../providers/UserProvider";
import {getAuth} from "firebase/auth";

import {UserContext} from "../providers/UserProvider";
const auth = getAuth(app);

const UserDropdown = () => {
    const userInfo = useContext(UserContext);

    // First time it draws, it executes
    useEffect(()=>{
        if (userInfo.user == null)
            return;


        //
        // if (user){
        //     history.push("/dashboard")
        // } else {
        //     history.push("/")
        // }
    },[userInfo]);

    async function signOut(){
        try {
            if (auth) {
                await auth.signOut();
                console.log("signed out successfully");
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    let localUser = (userInfo.user) ? userInfo.user.displayName : "";

    return (

        <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link'>
                <MDBIcon icon="user"/>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
                <MDBCard alignment='center' class=".z-depth-0 border-bottom" style={{ maxWidth: '22rem' }} >
                    <MDBCardBody>
                        <MDBCardTitle>{localUser}</MDBCardTitle>
                        <MDBCardText>Administrator</MDBCardText>
                    </MDBCardBody>
                </MDBCard>
                <MDBDropdownItem>
                    <MDBDropdownLink>Profile</MDBDropdownLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                    <MDBDropdownLink onClick={signOut}>Sign out</MDBDropdownLink>
                </MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}

export default UserDropdown;