import React, {useState} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import firebase from 'firebase/compat/app'
import { Redirect } from "react-router-dom";
require('firebase/auth')
/**
 * User enters his or her registration information in this form.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PASSWORD_INFO_MESSAGE = "Password must be at least 8 character long, at least 1 capital letter and a number";

const RegistrationForm = () => {
    const [email, setEmail] = useState(null);
    const [emailsMatch, setEmailsMatch] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("")
    const [password, setPassword] = useState(null);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(PASSWORD_INFO_MESSAGE);
    const [isValidPassword, setValidPassword] = useState(false);
    const [redirect, setRedirect] = useState("");

    const register = (event) => {
        if (!isValidInput())
            return;

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                console.log('user: ' + user);
                console.log("redirecting...")
                // need to redirect to home page
                setRedirect('/');
            }).then((ret) => {

            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;

                if (errorCode === 'auth/email-already-in-use' || errorCode === 'auth/invalid-email'){
                    setEmailErrorMessage(errorMessage);
                } else {
                    setPasswordErrorMessage(errorMessage);
                }
            });
    };

    /**
     * Validate input for the password.
     * It mast be 8 characters long, has at least one capital letter
     * and number
     * @param event the event from ui
     */
    const validatePassword = (event) => {
        // verify password requirements
        var value = event.target.value;

        if (!regexCheckForCapitalLetter(value)){
            setPasswordErrorMessage('Password must contain at least 1 uppercase letter.');
            return;
        }

        if (!regexCheckForDigit(value)) {
            setPasswordErrorMessage('Password must contain at least 1 digit.');
            return;
        }

        if (value.length < 8 || exceedsMaxLen(value)){
            setValidPassword(false);
            setPasswordErrorMessage(PASSWORD_INFO_MESSAGE);
            return;
        }

        setPassword(value);
        setValidPassword(true);
        setPasswordErrorMessage('');
    }
    /**
     * Validates email for it being in the form abc12@domainname.dom
     * and for its length
     *
     * @param event the event from ui
     */
    const validateEmail = (event) => {
        var value = event.target.value;

        if (exceedsMaxLen(value)){
            setEmailErrorMessage("Email entered was to long. Must be less than 128 characters");
            return;
        }

        if (!regexEmail(value)){
            setEmailErrorMessage("Not a valid email.");
            setEmail(value);
        } else {
            setEmailErrorMessage("");
        }

        setEmail(value);
    }

    /**
     * Check the length of the field.
     * @param value the value to be checked
     * @returns {boolean} true if check passes
     */
    function exceedsMaxLen(value){
        return value.length >= 128;
    }

    /**
     * Verify that emails match. If they do not match,
     * it sets a hint message. Otherwise, clears it.
     * @param event
     */
    const verifyEmailMatch = (event) => {
        if (event.target.value !== email){
            setEmailErrorMessage("Emails must match.")
        } else {
            setEmailErrorMessage("");
            setEmailsMatch(true);
        }
    }
    /**
     * Check that the value entered is an email
     * @param email a value to check
     * @returns {boolean} true if that is a valid email
     */
    function regexEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    /**
     * Check for the string to contain at least one digit
     */
    function regexCheckForDigit(password){
        const re = /.*[0-9].*/;
        return re.test(password);
    }
    /**
     * Check for the string to contain at least one uppercase letter
     */
    function regexCheckForCapitalLetter(password){
        const re = /[A-Z ]+/;
        return re.test(password);
    }
    function isValidInput(){
        return emailsMatch && isValidPassword;
    }

    return redirect !== "" ? <Redirect to={redirect}/> :
    <MDBContainer>
        <MDBRow>
            <MDBCol md="6">
                <form>
                    <p className="h5 text-center mb-4">Register</p>
                    <div className="grey-text">
                        <MDBInput label="Your name" icon="user" group type="text" validate error="wrong"
                                  success="right" />
                        <MDBInput onInput={(event) => {validateEmail(event)}} label="Your email" icon="envelope" group type="email" validate error="wrong"
                                  success="right" />
                        <MDBInput onInput={(event) => {verifyEmailMatch(event)}} label="Confirm your email" icon="exclamation-triangle" group type="text" validate
                                  error="wrong" success="right" />
                        <small id="emailHelp" className="form-text text-muted font-weight-bold">
                            {emailErrorMessage}
                        </small>
                        <MDBInput onInput={(event) => {validatePassword(event)}} label="Your password" icon="lock" group type="password" validate />
                        <small id="emailHelp" className="form-text text-muted font-weight-bold">
                            {passwordErrorMessage}
                        </small>
                    </div>
                    <div className="text-center">
                        <MDBBtn onClick={(event) => {register(event)}} color="primary">Register</MDBBtn>
                    </div>
                </form>
            </MDBCol>
        </MDBRow>
    </MDBContainer>;
};

export default RegistrationForm;