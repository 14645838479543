import React from 'react';
import {MDBTable, MDBTableHead, MDBTableBody, MDBCardLink, MDBBtn} from 'mdb-react-ui-kit';

import {store} from "../Store";

export default function Numbers() {
    return (
        <MDBTable hover>
            {/*<caption>List of phones</caption>*/}
            <MDBTableHead >
                <tr>
                    <th scope='col'>Name</th>
                    <th scope='col'>Number</th>
                    <th scope='col'>Call</th>
                    <th scope='col'>SMS Notifications</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                <tr>
                    <th scope='row'>Map</th>
                    <td>+14543222343</td>
                    <td>
                        <MDBCardLink>Senya</MDBCardLink>
                    </td>
                    <td>
                       <tr><MDBCardLink>Senya</MDBCardLink></tr>
                        <tr><MDBCardLink hlink="">Vera</MDBCardLink></tr>
                    </td>
                </tr>
                <tr>
                    <th scope='row'>2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                </tr>
                <tr>
                    <th scope='row'>3</th>
                    <td colSpan={2}>Larry the Bird</td>
                    <td>@twitter</td>
                </tr>
            </MDBTableBody>
        </MDBTable>

    );
}