import {combineReducers, configureStore} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
// import accountsReducer from '../src/features/accounts/accountSlice'
import {btelbApi} from "./services/clientEngine/btelbAPI";

export const store = configureStore({
    reducer: {
        [btelbApi.reducerPath]: btelbApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(btelbApi.middleware),
})

setupListeners(store.dispatch)



