import {MDBDropdownItem, MDBDropdownLink} from "mdb-react-ui-kit";
import React from "react";
import solutions_img from "../assets/solutions.svg";

const NewBusiness = () => {
    return (
        <div
            className='p-5 text-center bg-image'
            style={{ backgroundImage: solutions_img, height: 400 }}
        >
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <div className='text-white'>
                        <h1 className='mb-3'>New Business</h1>
                        <h4 className='mb-3'>Easily add new company with its own set of numbers</h4>
                        <a className='btn btn-outline-light btn-lg' href='#!' role='button'>
                            Add new business
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewBusiness;