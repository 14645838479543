import React, {Component, createContext, useState, useEffect} from "react";
import App, {generateUserDocument} from "../App";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { BrowserRouter as Router } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {ApiProvider} from "@reduxjs/toolkit/dist/query/react";
import {btelbApi} from "../services/clientEngine/btelbAPI";

import { Provider } from 'react-redux'
import {store} from "../Store";
// import store from './store'
/**
 * Creating dev, qa, and prod environments:
 * https://www.npmjs.com/package/env-cmd for .env-cmdrc
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
}

console.log(firebaseConfig);
console.log(process.env);

// Initialize Firebase only once. Hot reload debugger might try to do it
// few times
export const app =
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

// Create context to be used by children
const UserContext = createContext();

const UserProvider = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const providerValue = React.useMemo(() => ({
    user, setUser,
    token, setToken,
  }), [user, token]);

  useEffect(() => {
    const auth = getAuth(app);
    var onAuthStateChangedVar = onAuthStateChanged.bind(this);

    onAuthStateChanged(auth, user => {
      // Check for user status
      console.log("new onAuthStateChanged called")

        if (user){

          if (user.emailVerified) {
            console.log('Email is verified');
          }
          else {
            console.log('Email is not verified');
          }

          console.log('user has been logged in');

          user.getIdToken(true).then((token) =>{
            // logic to process token if needed
            setToken(token);
          });

          // save the user once signed in inside the state
          setUser(user);

          // https://stackoverflow.com/questions/51063571/how-to-pass-authorization-token-in-a-react-application
          // we shall redirect to a dashboard site when user is authorized
        } else {
          console.log('user has been signed out')
          setUser(null);
        }
    });

  },[]);

  return (
          <UserContext.Provider value={providerValue}>
            <Router>
              {/*<Provider store={store}>*/}
                <ApiProvider api={btelbApi}>
                  <App />
              </ApiProvider>

              {/*</Provider>*/}
            </Router>
          </UserContext.Provider>
      );
}

export { UserContext, UserProvider };

export default UserProvider;
