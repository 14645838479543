import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import SignInPage from "./auth/SignInPage";
import Numbers from "./pages/Numbers";
import Dashboard from "./pages/Dashboard";
import {CompanyPage} from "./features/accounts/CompanyPage";

// Conditional redirect
// https://www.pluralsight.com/guides/how-to-set-react-router-default-route-redirect-to-home
//


// if (typeof settings != "undefined"){
//     (<div>
//         <Redirect to={`/companies/${settings.lastAccessedCompanyId}?page=1`}/>
//     </div>)
// }
const Routes = () => {
    return (
        <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/numbers' component={Numbers}/>
            <Route exact path="/companies/:id" component={CompanyPage} />
            <Route
                render={function() {
                    return <h1>Not Found</h1>;
                }}
            />
        </Switch>
    )
}

export default Routes;
